




























































































































import UnderConstructionContent from '@/components/contents/UnderConstructionContent.vue';
import ContentSection from '@/components/elements/ContentSection.vue';
import HrefLink from '@/components/elements/HrefLink.vue';
import UndersideHeader from '@/components/elements/UndersideHeader.vue';
import Axios from 'axios';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {HrefLink, ContentSection, UnderConstructionContent, UndersideHeader}
           })
export default class KontaktContent extends Vue {
  private nameRules: object[] = [
    v => !!v || 'Feld muss ausgefüllt werden.'
  ];
  private descriptionRules: object[] = [
    v => !!v || 'Feld muss ausgefüllt werden.'
  ];
  private datenschutzRules: object[] = [
    v => !!v || 'Um eine Mail zu versenden muss der Datenschutzbestimmung zugestimmt werden.'
  ];
  private emailRules: object[] = [
    v => !!v || 'E-mail wird benötigt',
    v => /.+@.+/.test(v) || 'E-mail ist nicht korrekt'
  ];

  private valid: boolean = false;
  private contactData: Contact = new Contact();
  private successMessage:boolean = false;

  private sendMail() {
    //@ts-ignore
    if (this.$refs.form.validate() && this.contactData.checkDatenschutz) {
      this.contactData._csrf = <string> document.querySelector('#token')!.getAttribute('content');
      Axios.post('/api/mails', this.contactData)
        .then(value => {
          this.successMessage = true;
        })
        .catch((err) => console.log(err));
    }
  }
}

class Contact {
  firstname: string = '';
  lastname: string = '';
  mail: string = '';
  telefon: string = '';
  message: string = '';
  checkDatenschutz: boolean = false;
  _csrf: string = '';
}
