












import KontaktContent from '@/components/contents/KontaktContent.vue';
import UndersideHeader from '@/components/elements/UndersideHeader.vue';
import BaseSite from '@/views/BaseSite.vue';
import Page from '@/components/elements/Page.vue';
import { Component, Vue } from 'vue-property-decorator';
@Component({
             components: {
               KontaktContent,
               UndersideHeader,
               Page
             },
           })
export default class SiteKontakt extends BaseSite {
  private contactPage: object = {menuActive:false};
}
